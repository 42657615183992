.navbarstatic3 {
  .active{
    height: 7rem;
    width: 100%;
    position: absolute;
    top: 20px;
    display: flex;
    justify-content: flex;
    align-items: center;
    z-index: 15;
    padding-left: 11%;
    padding-right: 11%;
    min-width: 320px;
  }

  .navbar-left-container {
    padding-top: 3px;
    display: flex;
    justify-content: start;
    align-items: start;
    width: 33%;
  }

  .lang-button {
    padding: 8px 12px;
    background-color: transparent;
    border: none;
    color: white;
    transition: all 0.25s ease-out;
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  }

  .lang-button:hover {
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 1px;
  }

  .lang-gap {
    margin-left: 35px;
  }

  .navbar-middle-container {
    padding-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34%;
  }

  .navbar-title {
    color: white;
    font-size: 70px;
    letter-spacing: 2px;
    text-indent: 2px;
    font-family: 'Cinzel';
    font-weight: 500;
    text-decoration: none;
  }

  .navbar-right-container {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 33%;
  }

  .navLinks {
    border: none;
    background-color: transparent;
    padding: 8px 12px;
    margin-right: 35px;
    color: white;
    transition: all 0.25s ease-out;
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  }

  .navLinks:hover {
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 1px;
  }

  .navLink {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3px;
  }

  .flex-color {
    color: white;
  }

  .lg-view {
    display: flex;
  }

  .button-dimmed {
    color: grey;
  }

  @media screen and (max-width: 1700px) {
    .active {
      padding-left: 5%;
      padding-right: 5%;
    }
  }

  @media screen and (max-width: 1500px) {
    .lg-view {
      display: none;
    }

    .navbar-title {
      font-size: 60px;
    }

    .navbar-left-container {
      width: auto;
      padding-left: 12px;
      margin-left: 38px;
    }

    .navbar-middle-container {
      width: auto;
      padding-top: 0;
    }

    .navbar-right-container {
      margin-left: auto;
      width: auto;
      margin-right: 38px;
    }

    .active {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  @media screen and (max-width: 768px) {
    .md-view {
      display: none;
    }

    .active {
      height: 5.5rem;
    }
  }

  @media screen and (max-width: 576px) {
    .navbar-left-container {
      margin-left: 15px;
    }

    .navbar-right-container {
      margin-right: 15px;
    }

    .navbar-title {
      font-size: 40px;
    }

    .active {
      top: 0;
    }
  }

  /* height */
  @media screen and (max-height: 576px) { 
    .active {
      top: 0;
    }
  }
}