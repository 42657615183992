.Projects {
  .project-container {
    display: flex;
    align-items: start;
    justify-content: left;
    margin-left: auto;
    margin-right: auto;
    padding: 125px 10% 0;
    row-gap: 40px;
    flex-wrap: wrap;
    column-gap: 3.5%;
  }

  .project-item {
    display: block;
    align-items: center;
    justify-content: left;
    text-align: center;
    width: 31%;
    transition: all 800ms ease;
  }

  .project-image {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
  }

  .item-title {
    padding: 40px 0px;
  }

  .item-button {
    border: none;
    background-color: transparent;
    padding: 0px;
    color: black;
    transition: all 400ms ease;
    text-underline-offset: 8px;
    text-decoration-thickness: 1px;
  }

  .project-item:hover .item-button {
    color: #C89933;
    text-decoration: underline;
  }

  .project-dim {
    filter: brightness(80%) grayscale(80%);
  }

  .view-more-container {
    margin-top: 50px;
    padding-bottom: 25px;
    text-align: center;
  }

  .end-spacing {
    margin-bottom: 150px;
  }

  .more-button {
    text-decoration: none;
    background-color: transparent;
    color : black;
    border: none;
    transition: all 400ms ease;
  }

  .more-button:hover {
    color: #C89933;
  }

  .spacer {
    padding-top: 200px;
  }

  @media screen and (max-width: 1800px) {
    .project-container {
      flex-wrap: wrap;
      justify-content: left;
      row-gap: 25px;
      padding: 8% 10% 0;
      width: auto;
    }

    .end-spacing {
      margin-bottom: 125px;
    }  
  }

  @media screen and (max-width: 1400px) {
    .project-container {
      column-gap: 6%;
    }

    .project-item {
      width: 47%;
    }
  }

  @media screen and (max-width: 1050px) {
    .project-item {
      width: 100%;
      padding: 0px;
    }

    .end-spacing {
      margin-bottom: 100px;
    }  
  }
  
  @media screen and (max-width: 768px) {
    .spacer {
      padding-top: 100px;
    }

    .end-spacing {
      margin-bottom: 75px;
    }
  }

  @media screen and (max-width: 576px) {
    .project-container {
      padding: 15% 10% 0;
    }
    
    .item-title {
      padding: 30px 0px;
    }

    .end-spacing {
      margin-bottom: 60px;
    }
  }
}