.fade-detail-image {
  transform: translateY(5vh);
  visibility: hidden;
  transition: transform 400ms ease-out,
    visibility 400ms ease-out;
  will-change: opacity, visibility;
}

.fade-detail-image.is-visible {
  transform: none;
  visibility: visible;
}