.section-title-style {
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 3px;
  line-height: 37.5px;
  text-underline-offset: 5px;
  font-family: 'Cinzel', 'Inter-Regular', 'NotoSerifJP' ,'NotoSansJP-Regular';
}

.section-title-style-bold {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2.4px;
  line-height: 30px;
  text-underline-offset: 5px;
  font-family: 'Cinzel', 'Inter-Regular', 'NotoSerifJP' ,'NotoSansJP-Regular';
}

.section-title-norm-style {
  font-size: 20px;
  letter-spacing: 2.4px;
  line-height: 30px;
  font-family: 'Cinzel', 'Inter-Regular', 'NotoSerifJP', 'NotoSansJP-Regular';
}

.navbar-title-bold {
  font-size: 20px;
  letter-spacing: 2.7px;
  line-height: 30px;
  font-weight: 500;
  font-family: 'Cinzel', 'Inter-Regular', 'NotoSerifJP', 'NotoSansJP-Regular';
}

.section-title-norm-style-jap {
  font-size: 20px;
  letter-spacing: 2.4px;
  line-height: 30px;
  text-indent: -2.4px;
  font-family: 'Cinzel', 'Inter-Regular', 'NotoSerifJP', 'NotoSansJP-Regular';
}

.section-title-company-logo {
  font-size: 40px;
  color: white;
  font-family: 'Cinzel';
  letter-spacing: 5px;
  text-indent: 5px;
  font-weight: 500;
}

.hero-title-logo {
  font-size: 80px;
  font-family: 'Cinzel', 'NotoSerifJP';
  font-weight: 500;
}

.hero-title-logo2 {
  font-size: 55px;
  font-family: 'Cinzel', 'NotoSerifJP';
  font-weight: 500;
}

.section-content-bold-style {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2.4px;
  line-height: 30px;
  font-family: 'Inter-Regular', 'NotoSerifJP', 'NotoSansJP-Regular';
}

.section-content-style2 {
  font-size: 17px;
  letter-spacing: 2.04px;
  line-height: 25.5px;
  font-family: 'Inter-Regular', 'NotoSansJP-Regular';
}

.section-content-style {
  font-size: 16px;
  letter-spacing: 1.92px;
  line-height: 24px;
  font-family: 'Inter-Regular', 'NotoSansJP-Regular';
}

.section-content-italic-style {
  font-size: 16px;
  font-style: italic;
  letter-spacing: 1.92px;
  line-height: 24px;
  font-family: 'Inter-Regular', 'NotoSansJP-Regular';
}

.navigation-style-bold {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.92px;
  line-height: 24px;
  text-underline-offset: 5px;
  font-family: 'Cinzel', 'Inter-Regular', 'NotoSerifJP' ,'NotoSansJP-Regular';
}

.section-subcontent-style {
  font-size: 15px;
  letter-spacing: 1.8px;
  line-height: 22.5px;
  text-decoration: none;
  color: black;
  font-weight: 500;
  font-family: 'Cinzel', 'Inter-Regular', 'NotoSerifJP', 'NotoSansJP-Regular';
}

.section-subcontent-style-white {
  font-size: 15px;
  letter-spacing: 1.8px;
  line-height: 22.5px;
  text-decoration: none;
  color: white;
  font-weight: 500;
  font-family: 'Cinzel', 'Inter-Regular', 'NotoSerifJP', 'NotoSansJP-Regular';
}

.section-tiny-style {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.44px;
  font-family: 'Inter-Regular', 'NotoSansJP-Regular';
}

.page-hero-title-style {
  font-size: 50px;
  letter-spacing: 4.8px;
  text-indent: 4.8px;
  line-height: 60px;
  font-weight: 500;
  font-family: 'Cinzel', 'Inter-Regular', 'NotoSansJP-Regular';
}

.base-background {
  background-color: #f9f7f5;
}

.base-background2 {
  background-color: #3F612D;
}

.base-background3 {
  background-color: #3F612D;
}

.lg-container {
  padding-left: 11%;
  padding-right: 11%;
}

.dimmed {
  filter: brightness(60%);
}

.remove-decoration {
  text-decoration: none;
}

@media screen and (max-width: 1200px) {
  .page-hero-title-style {
    font-size: 30px;
  }

  .hero-title-logo {
    font-size: 60px;
    font-family: 'Cinzel', 'NotoSerifJP';
    font-weight: 500;
  }
}

@media screen and (max-width: 768px) {
  .page-hero-title-style {
    font-size: 25px;
  }

  .hero-title-logo {
    font-size: 45px;
  }

  .hero-title-logo2 {
    font-size: 45px;
  }
}

@media screen and (max-width: 576px) {
  .section-title-style {
    font-size: 20px;
    letter-spacing: 2.4px;
    line-height: 30px;
  }

  .page-hero-title-style {
    font-size: 22px;
  }

  .navigation-style-bold {
    font-size: 14px;
    letter-spacing: 1.68px;
    line-height: 21px;
  }

  .section-content-italic-style {
    font-size: 14px;
    font-style: italic;
    letter-spacing: 1.68px;
    line-height: 21px;
  }

  .section-content-style {
    font-size: 14px;
    letter-spacing: 1.68px;
    line-height: 21px;
  }

  .section-content-style2 {
    font-size: 15px;
    letter-spacing: 1.8px;
    line-height: 22.5px;
  }

  .section-subcontent-style {
    font-size: 13px;
    letter-spacing: 1.56px;
    line-height: 19.5px;
  }

  .section-subcontent-style-white {
    font-size: 13px;
    letter-spacing: 1.56px;
    line-height: 19.5px;
  }

  .lg-container {
    padding-left: 10%;
    padding-right: 10%;
  }

  .hero-title-logo {
    font-size: 35px;
  }

  .hero-title-logo2 {
    font-size: 35px;
  }
}