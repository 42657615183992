.LoadingDefault {
  background-color: #f9f7f5;
  height: 100svh;
  width: 100%;
}

.LoadingDark {
  position: absolute;
  background-color: #323031;
  height: 100svh;
  width: 100%;

  .loading-container {
    display: flex;
    color: white;
    width: 100%;
    height: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  
  .periods {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }

  .dot {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    margin: 0 10px;
    animation: up-down-wave 3s ease-in-out infinite;
  }

  .dot1 {
    animation-delay: 0s;
  }
  
  .dot2 {
    animation-delay: 0.3s;
  }
  
  .dot3 {
    animation-delay: 0.6s;
    animation-name: up-down-wave, blink-wave;
  }  
}

@keyframes up-down-wave {
  0%, 20% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-10px);
  }
  50%, 100% {
    transform: translateY(0);
  }
}
