.InfoCardStyle10 {
  .infocard-container {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0 10%;
  }

  .style10-container {
    display: block;
    max-width: 1450px;
    width: 100%;
  }

  .infocard-row-text {
    margin-top: 100px;
    display: flex;
    justify-content: end;
    align-content: end;
  }

  .info-col-image {
    margin-left: auto;
  }

  .info-image {
    width: 100%;
  }

  .info-col-text {
    margin-left: 70px;
    width: 410px;
  }

  .navigation-button {
    color: black;
    transition: all 400ms ease;
  }

  .navigation-button:hover {
    color: #C89933;
  }

  @media screen and (max-width: 1200px) {
    .info-col-image {
      display: none;
    }
    
    .infocard-row-text {
      justify-content: end;
      align-content: end;
      margin-top: 75px;
    }

    .info-col-text {
      margin-left: 0px;
    }
  }

  @media screen and (max-width: 768px) {
    .infocard-row-text {
      margin-top: 50px;
    }
  }

  @media screen and (max-width: 576px) {
    .infocard-row-text {
      margin-top: 25px;
    }

    .info-col-text {
      width: auto;
    }
  }
}