.ImagePopup {
  .thumbnail {
    cursor: pointer;
    max-width: 100%;
    position: relative;
    z-index: 14;
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 16;
  }
  
  .popup-content {
    position: relative;
  }
  
  .close {
    position: absolute;
    top: -10px;
    right: -50px;
    font-size: 40px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    z-index: 16;
  }
  
  .full-image {
    max-width: 100%;
    max-height: 100%;
  }

  @media screen and (max-width: 1370px) {
    .full-image {
      max-width: 95%;
      max-height: 95%;
      max-width: 972px;
    }
  }
}