.Contact {
  .contact-container {
    margin-top: 150px;
    margin-bottom: 150px;
  }

  .spacer {
    padding-top: 150px;
  }

  @media screen and (max-width: 1200px) {
    .contact-container {
      margin-top: 75px;
      margin-bottom: 100px;
    }

    .spacer {
      padding-top: 125px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .contact-container {
      margin-top: 0;
      margin-bottom: 125px;
    }

    .spacer {
      padding-top: 125px;
    }
  }
}