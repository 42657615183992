.InfoCardStyle3 {
  .infocard-three-container {
    background-color: black;
    color: white;
  }

  .carousel-indicators {
    bottom: -80px;
  }

  .carousel .carousel-indicators button {
    border-radius: 50%;
    width: 12px;
    height: 12px;
  }

  .infocard-row-top {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 200px;
  }

  .infocard-row-bottom {
    padding-bottom: 240px;
    display: flex;
  }

  .infocard-bottom-container {
    display: flex;
  }

  .infocard-top {
    width: 50%;
  }

  .infocard-top-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1032px;
    margin-bottom: 250px;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: static;
    z-index: 14;
  }

  .wide-image {
    width: 1032px;
  }

  .img-left {
    position: absolute;
    left: 0;
    width: 10%;
    height: 100%;
    object-fit: cover;
    object-position: bottom right;
    filter: grayscale(70%) opacity(60%);
  }

  .img-right {
    position: absolute;
    right: 0;
    width: 10%;
    height: 100%;
    object-fit: cover;
    object-position: bottom left;
    filter: grayscale(70%) opacity(60%);
  }

  .infocard-image-column {
    margin-right: 25px;
  }

  .style3-top-carousel {
    position: static;
    z-index: 14;
  }

  .infocard-text-column {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-left: 25px;
  }

  @media screen and (max-width: 1600px) {
    .lg-view {
      display: none;
      visibility: hidden;
    }
    
    .wide-image {
      width: 100%;
      padding: 0 10%;
      display: flex;
      justify-content: center;
    }

    .infocard-top-container {
      margin-bottom: 175px;
    }
  }

  @media screen and (max-width: 1200px) {
    .infocard-top-container {
      display: block;
      width: auto;
    }

    .infocard-image-column {
      margin-right: 0px;
      margin-bottom: 150px;
    }

    .infocard-image {
      max-height: 700px;
      max-width: 500px;
    }

    .infocard-text-column {
      margin-left: 0px;
    }

    .infocard-top {
      width: 650px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .infocard-row-bottom {
      padding-bottom: 175px;
    }

    .infocard-top-container {
      margin-bottom: 125px;
    }

    .infocard-row-top {
      padding-top: 150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .infocard-top {
      width: 100%;
      padding: 0 10%;
    }

    .infocard-image {
      width: 100%;
    }

    .infocard-image-column {
      margin-bottom: 125px;
    }

    .infocard-row-top {
      padding-top: 150px;
    }

    .infocard-top-container {
      margin-bottom: 75px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .infocard-row-top {
      padding-top: 100px;
    }

    .infocard-image-column {
      margin-bottom: 110px;
    }

    .infocard-top-container {
      margin-bottom: 50px;
    }

    .infocard-row-bottom {
      padding-bottom: 150px;
    }
  }
}