.fade-in-place {
  opacity: 0;
  transform: translateY(1vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 1600ms ease-out,
    visibility 1600ms ease-out;
  will-change: opacity, visibility;
}

.fade-in-place.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}