.ItemList {
  .item-list {
    display: flex;
    align-items: start;
    justify-content: left;
    margin-left: auto;
    margin-right: auto;
    padding: 125px 15% 0;
    row-gap: 50px;
    flex-wrap: wrap;
    column-gap: 5%;
  }

  .list-item {
    display: block;
    align-items: center;
    justify-content: left;
    text-align: center;
    width: 30%;
    transition: all 800ms ease;
  }

  .content-image {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
  }

  .item-title {
    padding: 40px 0px;
  }

  .item-button {
    border: none;
    background-color: transparent;
    padding: 0px;
    color: black;
    transition: all 400ms ease;
  }

  .list-item:hover .item-button {
    color: #C89933;
  }

  .item-dim {
    filter: brightness(80%) grayscale(80%);
  }

  .view-more-container {
    margin-top: 50px;
    text-align: center;
  }

  .end-spacing {
    margin-bottom: 150px;
  }

  .more-button {
    text-decoration: none;
    background-color: transparent;
    color : black;
    border: none;
    transition: all 400ms ease;
  }

  .more-button:hover {
    color: #C89933;
  }

  @media screen and (max-width: 1650px) {
    .item-list {
      flex-wrap: wrap;
      justify-content: left;
      row-gap: 30px;
      padding: 8% 10% 0;
      width: auto;
    }
  }

  @media screen and (max-width: 1400px) {
    .item-list {
      column-gap: 6%;
    }

    .list-item {
      width: 47%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .item-list {
      padding: 8% 10% 0;
    }

    .list-item {
      width: 100%;
      padding: 0px;
    }

    .item-image {
      aspect-ratio: 3/2;
    }

    .content-image {
      object-fit: cover;
      object-position: bottom center;
    }
  }

  @media screen and (max-width: 576px) {
    .item-list {
      padding: 15% 10% 0;
    }

    .item-title {
      padding: 30px 0px;
    }

    .end-spacing {
      margin-bottom: 100px;
    }
  }
}