.InfoCardStyle2 {
  .infocard-two-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-size: cover;
    background-position: bottom center;
  }

  .infocard-col {
    display: block;
    padding: 200px 0px 250px;
  }

  .infocard-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .infocard-container {
    width: auto;
    margin: 0 10%;
    margin-bottom: 125px;
    max-width: 800px;
  }

  @media screen and (max-width: 1200px) {
    .infocard-col {
      padding: 150px 0px 175px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .infocard-container {
      margin-bottom: 75px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .infocard-container {
      width: 100%;
      margin: 0px 5%;
      margin-bottom: 50px;
    }

    .infocard-col {
      padding: 100px 5% 150px;
    }
  }
}