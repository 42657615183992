.AboutUs {
  .activity-container {
    padding-bottom: 150px;
    background-color: #f9f7f5;
  }

  .activity-row {
    padding-top: 150px;
  }

  .group-row {
    margin-top: 150px;
  }

  .promo-title {
    padding-top: 200px;
    text-align: center;
  }

  .title-line {
    display: inline;
    padding-bottom: 5px;
    border-bottom: 5px solid #3F612D;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 576px) {
    .activity-row {
      padding-top: 100px;
    }

    .group-row {
      margin-top: 100px;
    }

    .promo-title {
      padding-top: 150px;
    }
  }
}