.main-navbar {
  .active{
    height: 5rem;
    width: 100%;
    position: fixed;
    top: 0px;
    transition: 0.3s linear;
    display: flex;
    justify-content: flex;
    align-items: center;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    z-index: 15;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
    padding: 0px 11%;
    min-width: 320px;
  }
  .hidden{
    height: 5rem;
    width: 100%;
    z-index: 15;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
    position: fixed;
    top: -80px;
    transition: 0.5s ease;
    min-width: 320px;
  }

  .navbar-left-container {
    padding-top: 3px;
    display: flex;
    justify-content: start;
    align-items: start;
    width: 33%;
  }

  .lang-button {
    padding: 8px 12px;
    background-color: transparent;
    border: none;
    color: white;
    transition: all 0.25s ease-out;
  }

  .lang-button:hover {
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 1px;
  }
  
  .lang-gap {
    margin-left: 35px;
  }

  .navbar-middle-container {
    padding-top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34%;
  }

  .navbar-title {
    color: white;
    font-size: 50px;
    font-family: 'Cinzel';
    font-weight: 500;
    text-decoration: none;
  }

  .navbar-right-container {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 33%;
  }

  .navLinks {
    border: none;
    background-color: transparent;
    padding: 8px 12px;
    margin-right: 35px;
    color: white;
    transition: all 0.25s ease-out;
  }
  
  .navLinks:hover {
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 1px;
  }

  .navLink {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3px;
  }

  .flex-color {
    color: black;
  }

  .lg-view {
    display: flex;
  }

  .button-dimmed {
    color: grey;
  }

  @media screen and (max-width: 1700px) {
    .active {
      padding: 0px 5%;
    }
  }

  @media screen and (max-width: 1500px) {
    .lg-view {
      display: none;
    }

    .navbar-title {
      font-size: 40px;
    }

    .navbar-left-container {
      width: auto;
      padding-left: 12px;
      margin-left: 38px;
    }

    .navbar-middle-container {
      width: auto;
    }

    .navbar-right-container {
      margin-left: auto;
      width: auto;
      margin-right: 38px;
    }

    .active {
      padding: 0px;
    }
  }

  @media screen and (max-width: 768px) {
    .md-view {
      display: none;
    }

    .active {
      height: 4rem;
    }

    .hidden {
      height: 4rem;
    }
  }

  @media screen and (max-width: 576px) {
    .navbar-left-container {
      margin-left: 15px;
    }

    .navbar-right-container {
      margin-right: 15px;
    }
  }
}