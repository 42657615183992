.Clubs {
  .spacing {
    margin-top: 125px;
  }

  .club-item {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .club-container {
    max-width: 1450px;
    padding: 150px 0 0;
    width: 100%;
    display: flex;
  }

  .location-right {
    justify-content: end;
    text-align: end;
  }

  .location-middle {
    justify-content: center;
    text-align: center;
  }

  .location-left {
    justify-content: start;
    text-align: start;
  }

  .club-block {
    display: block;
    width: 900px;
  }

  .club-desc {
    margin-top: 50px;
  }

  .end-spacer {
    padding-top: 250px;
  }

  @media screen and (max-width: 1800px) {
    .club-container {
      margin: 0;
      padding: 125px 10% 0;
      max-width: none;
    }
  }

  @media screen and (max-width: 1200px) {
    .spacing {
      margin-top: 50px;
    }

    .club-block-right {
      width: 700px;
    }
  }

  @media screen and (max-width: 768px) {
    .club-desc {
      margin-top: 50px;
    }

    .club-block-right {
      width: auto;
    }

    .location-middle {
      justify-content: start;
      text-align: start;
    }

    .left-to-right {
      justify-content: end;
      text-align: end;
    }
  }

  @media screen and (max-width: 576px) {
    .club-desc {
      margin-top: 25px;
    }

    .end-spacer {
      padding-top: 150px;
    }
  }
}