.Careers {
  .spacing {
    margin-top: 150px;
  }

  .spacing2 {
    margin-top: 175px;
  }

  .title-grid {
    grid-area: vjgaTitle;
  }

  .contact-grid {
    grid-area: contact;
  }

  .project-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "vjgaTitle image1 image2"
                         "image3    image4 contact"
                         ".         image5 image6";
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    row-gap: 60px;
    column-gap: 3.5%;
    padding: 0 10%;
  }

  .project-item {
    display: block;
    align-items: center;
    justify-content: left;
    text-align: center;
    transition: all 800ms ease;
  }

  .project-image {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
  }

  .vjga-title {
    text-align: left;
  }

  .block-title {
    margin-bottom: 25px;
    background: linear-gradient(90deg, rgba(200,153,51,1) 0%, rgba(231,231,108,1) 96%);
    -webkit-text-fill-color: transparent; 
    -webkit-background-clip: text;
  }

  .project-item {
    text-align: start;
  }

  .end-spacing {
    margin-bottom: 150px;
  }

  .navigation-button {
    color: black;
    transition: all 400ms ease;
  }

  .navigation-button:hover {
    color: #C89933;
  }

  .hero-title-logo3 {
    font-size: 70px;
    font-family: 'Cinzel', 'NotoSerifJP';
    font-weight: 500;
    line-height: 90px;
  }

  @media screen and (max-width: 1800px) {
    .project-container {
      padding: 0 10%;
      width: auto;
    }

    .hero-title-logo3 {
      font-size: 50px;
      line-height: 70px;
    }

    .block-title {
      margin-bottom: 10px;
    }  
  }

  @media screen and (max-width: 1550px) {
    .project-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: "vjgaTitle image1"
                           "image2    image3"
                           "image4    contact"
                           "image5    image6";
      column-gap: 5%;
    }

    .hero-title-logo3 {
      font-size: 70px;
      line-height: 90px;
    }
  }

  @media screen and (max-width: 1200px) {
    .spacing {
      margin-top: 100px;
    }

    .spacing2 {
      margin-top: 100px;
    }

    .end-spacing {
      margin-bottom: 100px;
    }

    .hero-title-logo3 {
      font-size: 50px;
      line-height: 70px;
    }
  }

  @media screen and (max-width: 1050px) {
    .spacing {
      margin-top: 75px;
    }

    .title-grid {
      display: none;
    }

    .project-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: "image1 image2" "image3 image4"
                           "contact contact" "image5 image6";
      column-gap: 5%;
      row-gap: 7%;
      padding: 0% 10% 30%;
    }

    .hero-title-logo3 {
      font-size: 70px;
      line-height: 90px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .end-spacing {
      margin-bottom: 75px;
    }

    .hero-title-logo3 {
      font-size: 45px;
      line-height: var(--bs-body-line-height);
    }

    .block-title {
      margin-bottom: 15px;
    }  
  }

  @media screen and (max-width: 576px) {
    .spacing {
      margin-top: 50px;
    }
    
    .spacing2 {
      margin-top: 125px;
    }

    .hero-title-logo3 {
      font-size: 35px;
    }
  }
}