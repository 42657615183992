.PageTitle2 {
  .page-container {
    padding-top: 50px;
  }

  .page-row {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15%;
  }

  .page-row-top {
    display: flex;
    margin-bottom: 75px;
  }

  .title-container {
    width: 50%;
    text-align: end;
    padding-right: 50px;
  }

  .highlighted-space {
    margin-top: 10px;
  }

  .highlighted-title {
    background-color: #3F612D;
    display: inline;
    padding: 5px 10px 0;
    color: white;
    justify-content: center;
    align-self: center;
  }

  .page-text-col {
    display: flex;
    justify-content: start;
    align-content: start;
    text-align: right;
    width: 50%;
    margin-right: 50px;
  }

  .page-img-col {
    width: 50%;
    margin-left: 50px;
  }

  .title-image {
    width: 100%;
    height: 100%;
  }
  
  .image-block {
    width: auto;
  }

  .border-line {
    display: none;
  }

  @media screen and (max-width: 1650px) {
    .page-row {
      display: block;
      width: auto;
      padding: 0 10%;
    }

    .page-row-top {
      margin-bottom: 70px;
      padding: 0 10%;
    }
  
    .title-container {
      width: 650px;
      justify-content: end;
      padding-right: 0;
    }

    .page-text-col {
      width: 100%;
      margin-right: 0px;
      text-align: left;
    }

    .page-img-col {
      width: 650px;
      height: 100%;
      margin-top: 75px;
      margin-left: auto;
    }

    .text-block {
      width: 700px;
    }
  }

  @media screen and (max-width: 1200px) {
    .page-img-col {
      width: 100%;
    }
    
    .text-block {
      width: 100%;
    }

    .border-line {
      display: flex;
      padding-bottom: 50px;
      border-bottom: 5px solid #3F612D;
      width: 40%;
      margin-left: auto;
      margin-right: auto;
    }

    .page-container {
      padding-bottom: 25px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .title-container {
      width: auto;
      justify-content: start;
      text-align: start;
      padding-right: 0;
    }

    .page-row-top {
      margin-bottom: 50px;
    }
  }

  @media screen and (max-width: 576px) {
    .page-img-col {
      margin-top: 50px;
    }

    .page-row-top {
      margin-bottom: 25px;
    }
  }
}