.PageTitle {
  .page-hero {
    padding: 50px 10% 0px;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .hero-desc {
    margin-top: 25px;
    width: 625px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  @media screen and (max-width: 1200px) {
    .page-hero {
      padding-bottom: 50px;
    }
  }

  @media screen and (max-width: 800px) {
    .page-hero {
      padding-left: 9%;
      padding-right: 9%;
    }

    .hero-desc {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    .hero-desc {
      width: 100%;
    }

    .page-hero {
      padding: 50px 10% 25px;
    }
  }
}