.Members {
  .member-container {
    display: flex;
    padding: 75px 15% 10%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .member-list-text-style {
    font-size: 20px;
    letter-spacing: 2.4px;
    line-height: 30px;
    font-family: 'Cinzel', 'Inter-Regular', 'NotoSerifJP', 'NotoSansJP-Regular';
  }

  .member-list-name-style {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2.4px;
    line-height: 30px;
    font-family: 'Cinzel', 'Inter-Regular', 'NotoSerifJP' ,'NotoSansJP-Regular';
  }

  .list-container {
    display: block;
    width: 100%;
  }

  .member-row-header {
    display: flex;
    padding-bottom: 25px;
    justify-content: center;
    align-items: center;
    column-gap: 3%;
  }

  .member-row {
    display: flex;
    margin-top: 25px;
    padding: 15px 0px;
    background-color: white;
    transition: all 400ms ease;
    color: black;
    column-gap: 3%;
  }

  .member-row:hover {
    transform: translateY(-7px);
    box-shadow: 0 5px 10px rgba(63, 97, 45, 0.5);
  }

  .member-row-dim {
    filter: brightness(98%);
    color: grey;
  }

  .name-col {
    width: 24%;
    overflow-wrap: break-word;
  }

  .company-col {
    padding-left: 15px;
    width: 37%;
    text-align: end;
    overflow-wrap: break-word;
  }

  .website-col {
    padding-right: 15px;
    width: 37%;
    text-align: start;
    word-break: break-all;
  }

  .sm-view {
    display: none;
  }

  @media screen and (max-width: 2100px) {

    .company-col {
      width: 35%;
    }
  
    .website-col {
      width: 35%;
    }

    .member-list-text-style {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1500px) {
    .member-container {
      padding-left: 10%;
      padding-right: 10%;
    }
    
    .member-row-header {
      column-gap: 5%;
    }

    .member-row {
      column-gap: 5%;
    }
  }

  @media screen and (max-width: 1200px) {
    .member-list-text-style {
      font-size: 12px;
    }

    .member-list-name-style {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1000px) {
    .sm-view {
      display: flex;
    }

    .member-container {
      padding-top: 75px;
      padding-bottom: 100px;
    }

    .member-row-header {
      padding-bottom: 0px;
    }

    .md-view {
      display: none;
    }

    .member-row {
      display: block;
    }

    .company-col {
      padding: 0px 5px;
      width: 100%;
      text-align: center;
    }
  
    .website-col {
      padding: 0px 5px;
      width: 100%;
      text-align: center;
    }

    .name-col {
      width: 100%;
      padding: 0px 5px;
      overflow-wrap: break-word;
    }
  }
}