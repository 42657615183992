.ProjectPromoHeroImage {
  .project-promo-container {
    position: relative;
  }

  .promo-text {
    z-index: 1;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .promo-text-container {
    display: block;
  }

  .text-header {
    color: white;
    font-size: 16px;
    letter-spacing: 3px;
    text-indent: 3px;
    line-height: 30px;
    font-family: 'Inter-Regular', 'NotoSansJP-Regular';
    padding-bottom: 5px;
    border-bottom: 2px solid;
    border-color: rgba(255,255,255, 0.4);
    width: 100px;
  }

  .text-name {
    color: white;
    font-weight: 500;
    font-size: 35px;
    letter-spacing: 3px;
    text-indent: 3px;
    line-height: 35px;
    font-family: 'Cinzel', 'Inter-Regular', 'NotoSerifJP' ,'NotoSansJP-Regular';
    padding: 25px 10px 0px;
    text-align: center;
  }

  .text-row {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .hero-image {
    max-height: 550px;
    width: 100%;
    object-fit: cover;
    object-position: bottom center;
    transition: all 1600ms ease;
    filter: brightness(50%);
  }

  .project-promo-container:hover .hero-image {
    filter: brightness(85%); 
  }

  @media screen and (max-width: 1200px) {
    .hero-image {
      height: 350px;
      object-fit: cover;
    }

    .text-name {
      font-size: 30px;
      padding-top: 20px;
    }
  }
}