.Events {
  .events-container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 2500px;
    padding: 0px 10% 200px;
  }

  .events-col {
    display: block;
    margin-right: 75px;
    width: 70%;
  }
  
  .events-feed {
    margin-left: auto;
    margin-right: auto;
    width: 30%;
    margin-top: 50px;
  }

  .event-row {
    display: flex;
    background-color: white;
    transition: all 400ms ease;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    margin-top: 50px;
  }

  .event-row:hover {
    transform: translateY(-7px);
  }

  .event-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: grey;
  }

  .view-more-container {
    margin-top: 50px;
    text-align: center;
  }

  .img-container {
    width: 35%;
  }

  .event-details {
    display: block;
    justify-content: center;
    align-content: center;
    margin-left: auto;
    margin-right:auto;
    padding: 25px 75px 25px 50px;
    width: 65%;
  }

  .event-detail {
    margin-top: 25px;
  }

  .event-link {
    margin-top: 25px;
  }

  .event-tag {
    display: inline;
    font-size: 15px; 
    letter-spacing: 1.8px;
    line-height: 22.5px; 
    font-weight: bold;
    color: white;
    font-family: 'Inter-Regular', 'NotoSerifJP';
    background: black;
    padding: 3px 7px;
  }

  .event-title {
    margin-top: 15px;
    font-size: 22px;
    letter-spacing: 2.64px;
    line-height: 33px;
    font-weight: bolder;
    font-family: 'Inter-Regular', 'NotoSerifJP';
  }

  .event-nodes {
    margin-top: 14px;
    font-size: 14px;
    letter-spacing: 1.68px;
    line-height: 21px;
    color: black;
    font-family: 'Inter-Regular';
  }

  .node-icon {
    justify-content: center;
    align-content: center;
    padding-right: 12px;
  }

  .event-nodes {
    display: flex;
  }

  .node-segments {
    display: flex;
    padding-right: 30px;
  }

  .node-segment-end {
    display: flex;
  }

  .location-container {
    display: block;
  }

  .instagram-feed {
    border: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .link-color {
    color : black;
    transition: all 400ms ease;
  }

  .link-color:hover {
    color: #C89933;
  }

  .flyer-link {
    text-underline-offset: 5px;
  }

  .view-more-button {
    border: none;
    background-color: transparent;
  }

  .media-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 100px;
  }

  .fb-logo {
    width: 50px;
    height: 50px;
    margin-right: 12px;
  }

  .fb-logo:hover {
    transform: translateY(-1px);
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(47, 230, 222, 0.5);
  }

  .ig-bg {
    margin-left: 12px;
  }

  .ig-bg:hover {
    transform: translateY(-1px);
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(174, 48, 119, 0.5);
  }

  .ig-logo {
    width: 50px;
    height: 50px;
  }

  @media screen and (max-width: 1800px) {
    .sm-view {
      display: none;
    }

    .events-col {
      width: 100%;
      margin-right: 0px;
    }
  }

  @media screen and (max-width: 1500px) {
    .event-details {
      padding-right: 50px;
    }
  }

  @media screen and (max-width: 1200px) {
    .view-more-container {
      width: 100%;
    }

    .events-col {
      display: flex;
      flex-wrap: wrap;
    }

    .row-container {
      width: 50%;
      padding: 0 2%;
    }

    .event-row {
      display: block;
    }

    .img-container {
      width: 100%;
    }

    .event-details {
      width: 100%;
      padding: 50px 25px 75px 25px;
    }

    .event-nodes {
      display: block;
    }

    .node-segments {
      padding: 10px 0px;
    }

    .node-segment-end {
      padding: 10px 0px;
    }

    .media-container {
      margin-top: 0px;
      margin-bottom: 75px;
    }
  }

  @media screen and (max-width: 850px) {
    .events-col {
      display: block;
    }

    .row-container {
      width: 100%;
      padding: 0;
    }

    .event-row {
      display: block;
    }

    .event-nodes {
      display: flex;
    }

    .node-segments {
      padding: 0px;
      padding-right: 30px;
    }
  
    .node-segment-end {
      padding: 0px;
    }
  }

  @media screen and (max-width: 768px) {
    .media-container {
      margin-top: 25px;
      margin-bottom: 50px;
    }

    .node-segments {
      padding-right: 25px;
    }

    .fb-logo {
      width: 40px;
      height: 40px;
      margin-right: 9px;
    }

    .ig-logo {
      width: 40px;
      height: 40px;
    }

    .ig-bg {
      margin-left: 9px;
    }

    .events-container {
      padding-bottom: 100px;
    }
  }

  @media screen and (max-width: 576px) {
    .event-nodes {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 1.44px;
    }

    .event-tag {
      font-size: 13px;
      letter-spacing: 1.56px;
      line-height: 19.5px;
    }

    .event-details {
      padding: 40px 25px;
    }

    .event-nodes {
      display: block;
    }

    .node-segments {
      padding: 10px 0px;
    }

    .node-segment-end {
      padding: 10px 0px;
    }
  }
}