.fade-from-right {
  opacity: 0;
  transform: translateX(10vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 1600ms ease-out,
    visibility 1600ms ease-out;
  will-change: opacity, visibility;
}

.fade-from-right.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}