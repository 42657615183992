.twoItem-carousel {
  .twoItem {
    display: flex;
    justify-content: center;
    position: static;
    z-index: 14;
    padding: 0 10%;
  }

  .twoItem-img {
    max-width: 700px;
    width: auto;
  }
  
  .left-img {
    margin-right: 25px;
  }

  .right-img {
    margin-left: 25px;
  }

  .carousel-indicators {
    bottom: -80px;
    z-index: 0;
  }

  .carousel .carousel-indicators button {
    border-radius: 50%;
    width: 12px;
    height: 12px;
  }

  .sm-view {
    display: none;
  }

  @media screen and (max-width: 1450px) {
    .twoItem-img {
      max-width: 700px;
      width: 525px;
    }
  }

  @media screen and (max-width: 1200px) {
    .lg-view {
      display: none;
    }

    .sm-view {
      display: block;
    }

    .oneItem-img {
      width: 100%;
      padding: 0 10%;
    }
  }

  @media screen and (max-width: 576px) {
    .oneItem-img {
      width: 100%;
      padding-left: 5%;
      padding-right: 5%;
    }
  }
}