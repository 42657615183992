.FullScreenHeroImage {
  min-height: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .image-container {
    z-index: 11;
    position: relative;
  }

  .fullscreen-image {
    width: 100%;
    height: 100svh;
    object-fit: cover;
    object-position: 50% 50%;
    filter: brightness(100%) grayscale(40%);
  }

  .hero-image-title {
    z-index: 13;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    color: white;
  }
  
  @media screen and (max-width: 768px) {
    .fullscreen-image {
      height: 400px;
    }

    .hero-image-title {
      display: flex;
      justify-content: center;
      align-items: center;
      top: 50%;
      transform: translateY(-50%);
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }

  /* MAX-HEIGHT */
  @media screen and (max-height: 576px) {
    .fullscreen-image {
      height: 450px;
    }

    .hero-image-title {
      display: flex;
      justify-content: center;
      align-items: center;
      top: 55%;
      transform: translateY(-50%);
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }
}