.menu-button {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 8px 12px;
}

.offcanvas {
  max-width: 320px;
  background-color: #3F612D;
  transition: transform 0.75s ease-in-out;
}

.offcanvas-backdrop {
  transition: opacity 0.25s ease-in-out;
}

.close-button {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 8px;
  margin: calc(-0.5 * var(--bs-offcanvas-padding-y)) 
          calc(var(--bs-offcanvas-padding-x)) 
          calc(-0.5 * var(--bs-offcanvas-padding-y)) auto;
}

.close-icon {
  width: 25px;
  height: 25px;
}

.offcanvas-title {
  margin: 50px 25px;
}

.offcanvas-container {
  padding-left: 0;
  padding-right: 0;
}

.menu-container {
  margin-left: 25px;
  margin-right: 25px;
}

.dropdown-content {
  visibility: hidden;              
  position: absolute;
  background-color: white;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  opacity: 0;             
  transition: opacity 0.4s ease-out, visibility 0.4s ease-out;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100%;
}

.dropdown-spacing-top {
  padding: 14px 0px 10px 10px;
}

.dropdown-spacing {
  padding: 10px 0px 10px 10px;
}

.dropdown-items {
  color: #323031;
  display: block;
  width: 100%;
  border: none;
  background-color: transparent;
  text-align: left;
  transition: all 0.25s ease-out;
}

.dropdown-items:hover {
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-thickness: 1px;
}

.dropdown-item {
  display: flex;
  align-items: center;
}

.offcanvas-item {
  margin-right: 25px;
  color: white;
}

.dropdown-icon {
  width: auto;
  padding-bottom: 2px;
  color: white;
}

.offcanvas-row {
  margin: 8px 0px;
}

.offcanvas-link {
  border: none;
  background-color: transparent;
  text-align: left;
  padding: 6px 0;
  width: 90%;
  color: white;
  transition: all 0.25s ease-out;
}

.offcanvas-link:hover {
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-thickness: 1px;
  text-decoration-color: white;
}

.offcanvas-footer {
  padding-top: 15px;
}

.footer-container {
  margin-left: 41px;
}

.offcanvas-lang-row {
  margin: 4px 0px;
}

.offcanvas-lang-link {
  width: auto;
  text-align: left;
  text-decoration: none;
  color: white;
  padding: 6px 0;
  background: none;
  border: none;
  transition: all 0.25s ease-out;
}

.offcanvas-lang-link:hover {
  text-decoration: underline;
  text-underline-offset: 8px;
  text-decoration-thickness: 1px;
}

.offcanvas-media {
  display: flex;
  margin: 4px 0px;
}

.media-first {
  margin-right: 25px;
}

.media-col {
  width: auto;
  margin-top: 8px;
  margin-bottom: 40px;
}

.fbLogo {
  width: 40px;
  height: 40px;
}

.fbLogo:hover {
  transform: translateY(-1px);
  border-radius: 50%;
  box-shadow: 0 5px 20px rgba(255,255,255, 0.2);
}

.igLogo {
  width: 40px;
  height: 40px;
}

.ig-bg:hover {
  transform: translateY(-1px);
  border-radius: 50%;
  box-shadow: 0 5px 20px rgba(255,255,255, 0.2);
}

.dropdown-dim {
  background-color: darkgrey;
}

@media screen and (max-height: 768px) { /* MAX HEIGHT */
  .offcanvas-header {
    padding: 11px 16px;
  }

  .offcanvas-title {
    margin: 0px 25px;
  }

  .fbLogo {
    width: 30px;
    height: 30px;
  }
  
  .igLogo {
    width: 30px;
    height: 30px;
  }
  
  .media-first {
    margin-right: 20px;
  }

  .media-col {
    margin-top: 4px;
    margin-bottom: 13px;
  }

  .offcanvas-footer {
    padding-top: 5px;
  }
}