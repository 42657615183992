.fullscreen-carousel {
  .carousel {
    z-index: 11;
  }

  .filter {
    position: absolute;
    z-index: 12;
    background-color: rgba(50,48,49, 0.55);
    width: 100%;
    height: 100svh;
    box-shadow: 0 0 10px 8px rgba(255,255,255,1) inset;
  }

  .home-carousel {
    width: 100%;
    height: 100svh;
    object-fit: cover;
    object-position: 50% 50%;
  }

  @media screen and (max-width: 576px) {
    .home-carousel {
      height: 400px;
    }

    .filter {
      height: 400px;
    }
  }

  @media screen and (max-height: 400px) {
    .home-carousel {
      height: 400px;
    }

    .filter {
      height: 400px;
    }
  }
}

