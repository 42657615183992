.InfoCardStyle4 {
  .style4-container {
    display: flex;
    width: 100%;
    border-top: rgba(0,0,0,0.5) solid 2px;
  }

  .style4-columns {
    width: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f7f5;
    position: relative;
  }

  .text-column {
    padding: 250px 0px;
    min-height: 900px;
  }

  .style4-overlay {
    width: 100%;
    height: 100%;
  }

  .style4-map {
    width: 100%;
    height: 100%;
  }

  .style4-column {
    width: 500px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; 
    transition: opacity 0.5s ease-out;
    opacity: 1;
  }

  .overlay.fade-out {
    opacity: 0;
  }

  .map-filter {
    width: 100%;
    height: 100%;
  }

  .style4-row {
    margin-top: 25px;
  }

  .style4-row-title {
    margin-bottom: 10px;
  }

  .style4-title {
    font-size: 35px;
    color: black;
    font-family: 'Cinzel', 'NotoSerifJP';
    letter-spacing: 5px;
    text-indent: 5px;
    font-weight: 500;
  }

  .project-link {
    color: black;
    transition: all 400ms ease;
  }

  .project-link:hover {
    color: #C89933;
  }

  @media screen and (max-width: 1200px) {
    .style4-container {
      display: block;
      height: auto;
    }

    .style4-columns {
      width: 100%;
    }

    .text-column {
      padding: 100px 0px;
      min-height: 650px;
    }

    .map-column {
      height: 650px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .text-column {
      padding: 100px 0px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .style4-column {
      padding: 0 10%;
    }
  }
}