@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter-Regular'), url(./Assets/Fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'NotoSansJP-Regular';
  src: local('NotoSansJP-Regular'), url(./Assets/Fonts/NotoSansJP-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'PlaywriteNL-Regular';
  src: local('PlaywriteNL-Regular'), url(./Assets/Fonts/PlaywriteNL-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'NotoSerifJP';
  src: local('NotoSerifJP'), url(./Assets/Fonts/NotoSerifJP.ttf) format('truetype');
}

@font-face {
  font-family: 'Cinzel';
  src: local('Cinzel'), url(./Assets/Fonts/Cinzel.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter-Regular', 'RobotoMono-Regular', 'Helvetica Neue',
  'NotoSansJP-Regular', 'Segoe UI', 'Roboto', sans-serif, 'PlaywriteNL-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   min-width: 320px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
