.ProjectIntroHeroImage {
  min-height: 800px;

  .hero-image-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .hero-image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  
  .project-header {
    height: 100svh;
    min-height: 800px;
  }

  .top-container {
    display: flex;
    flex-direction: column;
    height: 55%;
  }
  
  .project-nav {
    z-index: 15;
  }

  .project-title {
    position: relative;
    flex-grow: 1;
    background-color: #f9f7f5;
    text-align: center;
    align-content: end;
    overflow: hidden;
  }

  .scroll-image-view {
    border-top: 7px solid #3F612D;
    height: 45%;
    background-color: rgba(63, 97, 45, 0.5);
  }

  .scroll-text {
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
    padding-bottom: 30px;
  }

  .project-dates {
    position: absolute;
    font-size: 650px;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto; 
    margin-right: auto;
    line-height: 90%;
    opacity: 5%;
    letter-spacing: -50px;
    text-indent: -50px;
    text-align: center;
    z-index: 1;
  }

  .project-name-container {
    position: absolute;
    width: auto;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .project-header-text {
    display: inline-block;
    padding: 0px 25px;
    font-size: 30px;
    border-bottom: 1px solid black;
    margin-bottom: 25px;
    font-family: 'Inter-Regular', 'NotoSansJP-Regular';
  }

  .title-container {
    padding: 0 10px;
    margin-bottom: 50px;
  }
}