.InfoCardStyle6 {
  .style6-container {
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 200px 0 250px;
  }

  .info-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info-text-container {
    display: block;
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .bottom-container {
    margin-top: 100px;
  }

  .style6-img {
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 14;
    width: 100%;
  }

  .style6-image {
    width: 1000px;
  }

  .carousel-indicators {
    bottom: -80px;
  }

  .carousel .carousel-indicators button {
    border-radius: 50%;
    width: 12px;
    height: 12px;
  }

  .img-left {
    position: absolute;
    left: 0;
    width: 10%;
    height: 100%;
    object-fit: cover;
    object-position: bottom right;
    filter: grayscale(70%) opacity(60%);
  }

  .img-right {
    position: absolute;
    right: 0;
    width: 10%;
    height: 100%;
    object-fit: cover;
    object-position: bottom left;
    filter: grayscale(70%) opacity(60%);
  }

  @media screen and (max-width: 1850px) {
    .lg-view {
      display: none;
    }
  }

  @media screen and (max-width: 1400px) {
    .style6-image {
      width: auto;
    }
    
    .style6-container {
      padding-left: 10%;
      padding-right: 10%;
    }
  }

  @media screen and (max-width: 1200px) {
    .style6-container {
      padding: 150px 10% 175px;
    }

    .info-text-container {
      width: 650px;
    }

    .style6-container {
      padding-bottom: 175px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .info-text-container {
      width: 525px;
    }

    .bottom-container {
      margin-top: 75px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .info-text-container {
      width: 100%;
    }
    
    .style6-container {
      padding: 100px 10% 150px;
    }

    .bottom-container {
      margin-top: 50px;
    }
  }
}