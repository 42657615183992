.InfoCardStyle8 {
  .info-card {
    background-size: cover;
    background-position: bottom center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 300px 10%;
    width: 100%;
  }

  .info-segment {
    width: 50%;
    display: flex;
  }

  .info-text-container {
    display: flex;
    align-items: end;
    justify-content: end;
    margin-left: 55px;
    max-width: 750px;
  }

  .text-container {
    display: block;
    text-align: start;
  }

  .info-img-container {
    align-items: start;
    justify-content: start;
    margin-right: 55px;
    max-width: 768px;
  }

  .style8-img {
    position: static;
    z-index: 14;
  }

  .carousel-indicators {
    bottom: -80px;
  }

  .carousel .carousel-indicators button {
    border-radius: 50%;
    width: 12px;
    height: 12px;
  }

  @media screen and (max-width: 1700px) {
    .info-text-container {
      margin-left: 25px;
    }

    .info-img-container {
      margin-right: 25px;
    }
  }

  @media screen and (max-width: 1400px) {
    .info-img {
      width: 100%;
    }

    .info-text-container {
      margin-left: 20px;
    }

    .info-img-container {
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 1200px) {
    .info-container {
      flex-direction: column-reverse;
      width: 100%;
      padding: 200px 10% 225px;
    }

    .info-segment {
      width: 100%;
    }

    .text-container {
      margin-left: auto;
      margin-right: auto;
      width: 620px;
      text-align: center;
    }

    .info-text-container {
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-left: 0px;
      margin-bottom: 100px;
    }

    .info-img-container {
      align-items: center;
      justify-content: center;
      margin-right: 0px;
    }

    .style8-img {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .info-img {
      width: 650px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .text-container {
      width: 500px;
    }

    .info-text-container {
      margin-bottom: 125px;
    }

    .info-img {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 576px) {
    .info-container {
      padding: 100px 10% 150px;
    }

    .info-text-container {
      margin-bottom: 50px;
    }

    .text-container {
      width: 100%;
    }
  }
}