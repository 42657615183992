.Home {
  background-color: #323031;
  overflow: hidden;

  .home-carousel {
    position: relative;
  }

  .section-title-logo-style {
    font-size: 50px;
    color: white;
    font-family: 'Cinzel';
    letter-spacing: 5px;
    font-weight: 500;
  }

  .left-most-item {
    margin-right: 50px;
  }

  .right-most-item {
    margin-left: 50px;
  }

  .carousel-slogan-container {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    z-index: 13;
    width: 800px;
    bottom: 10vh;
    text-align: left;
    left: 7vw;
  }

  .slogan-container {
    display: block;
  }

  .section-quote-style-ja {
    font-size: 50px;
    font-weight: 500;
    width: 800px;
    font-family: 'NotoSerifJP';
    margin-bottom: 20px;
    letter-spacing: 2.4px;
    line-height: 82.5px;
    background-image: linear-gradient(90deg, #85FFBD 0%, #FFFB7D 100%);
    -webkit-text-fill-color: transparent; 
    -webkit-background-clip: text;
    text-shadow: 4px 4px 4px rgba(255, 255, 255, 0.1);
  }

  .section-quote-style-en {
    font-size: 35px;
    color: white;
    letter-spacing: 2.4px;
    padding-left: 2.4px;
    line-height: 60px;
    font-family: 'Inter-Regular', 'NotoSansJP-Regular';
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  }

  .home-vjga-logo-container {
    position: absolute;
    z-index: 13;
    bottom: 10vh;
    right: 7vw;
  }

  .vjga-logo-img {
    width: 200px;
    height: 200px;
    transition: transform 0.3s ease, box-shadow 1 ease;
  }

  .vjga-logo-img:hover {
    transform: translateY(-5px);
    border-radius: 50%;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  }

  .home-aboutus {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 300px 0px;
  }

  .aboutus-row {
    display: flex;
    max-width: fit-content;
    width: 1050px;
    margin-left: auto;
    margin-right: auto;
  }

  .aboutus-col {
    margin: auto;
    width: 50%;
  }

  .aboutus-col-text {
    padding: 0px 0px 150px;
  }

  .aboutus-col-img {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
  }

  .aboutus-img {
    max-height: 690px;
    object-fit: scale-down;
  }

  .home-ourWork {
    background-image: url("../Assets//Hope/hope-greyed-bg.jpg");
    background-size: cover;
    background-position: bottom left;
    color: white;
    padding: 150px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ourWork-row {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .ourWork-desc {
    display: flex;
    justify-content: center;
    padding: 0 10% 100px;
  }

  .ourWork-desc-box {
    max-width: 800px;
  }

  .ourWork-media {
    display: flex;
    justify-content: center;
    margin-top: 150px;
    margin-bottom: 30px;
  }

  .ourWork-fb-logo {
    width: 50px;
    height: 50px;
    margin-right: 12px;
  }

  .ourWork-fb-logo:hover {
    transform: translateY(-1px);
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(47, 230, 222, 0.5);
  }

  .ig-bg {
    margin-left: 12px;
  }

  .ig-bg:hover {
    transform: translateY(-1px);
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(174, 48, 119, 0.5);
  }

  .ourWork-ig-logo {
    width: 50px;
    height: 50px;
  }

  .ourWork-more {
    padding-left: 1.8px;
  }

  .home-promo-col {
    padding: 50px 0px;
  }

  .home-promo-row {
    margin: 150px 0px;
  }

  .sm-view {
    display: none;
  }

  .home-nav-link {
    transition: all 400ms ease;
  }

  .home-nav-link:hover {
    color: #C89933;
  }

  .carousel-menu-col {
    display: none;
  }

  .promo-title {
    padding-top: 150px;
    text-align: center;
  }

  .title-line {
    display: inline;
    padding-bottom: 5px;
    border-bottom: 5px solid #3F612D;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 1700px) {
    .carousel-slogan-container {
      left: 75px;
    }

    .home-vjga-logo-container {
      right: 75px;
    }

    .section-quote-style-ja {
      font-size: 45px;
      width: 750px;
      margin-bottom: 20px;
      letter-spacing: 2.4px;
      line-height: 60px;
    }
  
    .section-quote-style-en {
      font-size: 35px;
      letter-spacing: 2.4px;
      padding-left: 2.5px;
      line-height: 50px;
    }

    .vjga-logo-img {
      width: 175px;
      height: 175px;
    }
  }

  @media screen and (max-width: 1350px) {
    .carousel-slogan-container {
      width: 750px;
    }

    .vjga-logo-img {
      width: 150px;
      height: 150px;
    }

    .home-aboutus {
      padding: 250px 0px;
    }
  }

  @media screen and (max-width: 1200px) {
    .carousel-slogan-container {
      width: 600px;
      left: 50px;
    }

    .section-quote-style-ja {
      font-size: 35px;
      width: 575px;
      margin-bottom: 20px;
      letter-spacing: 2.4px;
      line-height: 50px;
    }
  
    .section-quote-style-en {
      font-size: 25px;
      letter-spacing: 2.4px;
      padding-left: 2.5px;
      line-height: 40px;
    }

    .home-vjga-logo-container {
      right: 50px;
    }
  
    .vjga-logo-img {
      width: 150px;
      height: 150px;
    }

    .home-aboutus {
      padding: 0px 0px;
    }

    .aboutus-row {
      display: block;
      width: 600px;
      padding: 150px 0px;
    }

    .aboutus-col-text {
      text-align: center;
      width: 100%;
      padding-bottom: 100px;
    }

    .aboutus-col-img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .aboutus-img {
      max-height: none;
      width: 100%;
    }

    .aboutus-col {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .ourWork-media {
      margin-top: 125px;
    }
  }

  @media screen and (max-height: 768px) { /* MAX HEIGHT */
    .home-vjga-logo-container {
      bottom: 80px;
    }

    .carousel-slogan-container {
      bottom: 80px;
    }
  }

  @media screen and (max-width: 1050px) {
    .home-vjga-logo-container {
      bottom: 40px;
      right: 40px;
    }
    
    .carousel-slogan-container {
      display: none;
    }

    .section-title-logo-style {
      font-size: 40px;
    }

    .carousel-menu-col {
      display: flex;
    }
    
    .carousel-title-col {
      margin-left: 38px;
      padding-left: 12px;
    }
    
    .carousel-menu-col {
      margin-left: auto;
      margin-right: 38px;
    }
  }

  @media screen and (max-width: 768px) {
    .vjga-logo-img {
      width: 150px;
      height: 150px;
    }

    .aboutus-row {
      width: 100%;
      padding: 125px 10%;
      margin: 0px;
    }

    .ourWork-desc {
      padding-bottom: 75px;
    }
  }

  @media screen and (max-height: 576px) { /* MAX HEIGHT */
    .carousel-slogan-container {
      display: none;
    }

    .home-vjga-logo-container {
      bottom: 40px;
      right: 40px;
    }

    .vjga-logo-img {
      width: 100px;
      height: 100px;
    }
  }

  @media screen and (max-width: 576px) {
    .home-vjga-logo-container {
      bottom: 20px;
      right: 20px;
    }

    .vjga-logo-img {
      width: 100px;
      height: 100px;
    }
  
    .aboutus-col-text {
      padding-bottom: 50px;
    }

    .home-ourWork {
      width: 100%;
      padding: 75px 5%;
    }

    .ourWork-desc {
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 50px;
    }

    .ourWork-fb-logo {
      width: 40px;
      height: 40px;
      margin-right: 9px;
    }
  
    .ourWork-ig-logo {
      width: 40px;
      height: 40px;
    }

    .ig-bg {
      margin-left: 9px;
    }

    .ourWork-more {
      padding-left: 1.56px;
    }

    .home-promo-row {
      margin: 100px 0px;
    }

    .promo-title {
      padding-top: 100px;
    }

    .carousel-title-col {
      margin-left: 15px;
    }
    
    .carousel-menu-col {
      margin-right: 15px;
    }
  }
}