.Footer {
  display: grid;
  grid-template-areas: 'info logos nav' 
                      'trademark trademark trademark';
  grid-auto-columns: minmax(0, 1fr);
  color: white;
  padding-top: 100px;
  padding-bottom: 50px;
  row-gap: 25px;

  .footer-item-info {
    grid-area: info;
    display: block;
  }

  .footer-item-logos {
    grid-area: logos;
    display: flex;
    justify-content: center;
    align-items: start;
  }

  .footer-item-nav {
    grid-area: nav;
    display: block;
    justify-content: end;
    text-align: end;
  }

  .footer-item-trademark {
    grid-area: trademark;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-size: 16px;
    letter-spacing: 0.5px;
    font-family: 'Cinzel';
    padding: 0px 25px;
  }

  .footer-bar {
    width: 100%;
  }

  .lang-button {
    padding: 0px;
    background-color: transparent;
    border: none;
    color: white;
    transition: all 400ms ease;
    margin-bottom: 2px;
  }
  
  .lang-button:hover {
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 1px;
  }

  .footer-brand-name {
    display: flex;
    justify-content: center;
    font-size: 50px;
    color: white;
    font-family: 'Cinzel';
    letter-spacing: 5px;
    font-weight: 500; 
    text-indent: 5px;
    text-decoration: none;
    margin-bottom: 100px;
  }
  
  .footer-fbLogo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .footer-fbLogo:hover {
    transform: translateY(-1px);
    border-radius: 50%;
    box-shadow: 0 5px 20px rgba(255,255,255, 0.2);
  }

  .ig-bg {
    margin-left: 10px;
  }

  .ig-bg:hover {
    transform: translateY(-1px);
    box-shadow: 0 5px 20px rgba(255,255,255, 0.2);
  }

  .footer-igLogo {
    width: 40px;
    height: 40px;
  }

  .footer-media-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }

  .vjga-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-vjga-logo {
    width: 110px;
    height: 110px;
  }

  .footer-vjga-logo:hover {
    transform: translateY(-1px);
    border-radius: 50%;
    box-shadow: 0 5px 20px rgba(255,255,255, 0.2);
  }

  .left-row {
    margin-bottom: 4px;
  }

  .right-row {
    margin-bottom: 4px;
  }

  .footer-text {
    text-decoration: none;
    color: white;
  }

  .footer-buttons {
    transition: all 400ms ease;
  }

  .footer-buttons:hover {
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 1px;
  }

  .email-split {
    display: none;
  }

  .section-title-norm-style-footer {
    font-size: 20px;
    letter-spacing: 2.4px;
    line-height: 30px;
    font-family: 'Cinzel', 'Inter-Regular', 'NotoSerifJP', 'NotoSansJP-Regular';
  }

  .section-title-norm-style-jap-footer {
    font-size: 20px;
    letter-spacing: 2.4px;
    line-height: 30px;
    text-indent: -2.4px;
    font-family: 'Cinzel', 'Inter-Regular', 'NotoSerifJP', 'NotoSansJP-Regular';
  }
}

@media screen and (max-width: 1300px) {
  .Footer {
    .email-full {
      display: none;
    }

    .email-split {
      display: flex;
    }
  }
}

@media screen and (max-width: 930px) {
  .Footer {
    .footer-brand-name {
      font-size: 40px;
    }
  }
}

@media screen and (max-width: 768px) {
  .Footer {
    grid-template-areas: 'info'
                      'nav'
                      'logos' 
                      'trademark';

    .footer-brand-name {
      margin-bottom: 25px;
      margin-top: 50px;
    }

    .email-full {
      display: flex;
    }

    .email-split {
      display: none;
    }
  }
}

@media screen and (max-width: 576px) {
  .Footer {
    padding-top: 50px;

    .footer-item-trademark {
      font-size: 12px;
      letter-spacing: 0.5px;
      font-family: 'Cinzel';
      padding: 0px 5px;
    }

    .footer-fbLogo {
      width: 35px;
      height: 35px;
      margin-right: 9px;
    }
  
    .ig-bg {
      margin-left: 9px;
    }
  
    .footer-igLogo {
      width: 35px;
      height: 35px;
    }

    .section-title-norm-style-footer {
      font-size: 16px;
      letter-spacing: 1.92px;
      line-height: 24px;
    }
  
    .section-title-norm-style-jap-footer {
      font-size: 16px;
      letter-spacing: 1.92px;
      line-height: 24px;
      text-indent: -1.92px;
    }

    .left-row {
      margin-bottom: 0px;
    }
  
    .right-row {
      margin-bottom: 0px;
    }

    .footer-brand-name {
      margin-bottom: 15px;
      margin-top: 25px;
    }
  }
}

@media screen and (max-width: 450px) {
  .Footer {
    .email-full {
      display: none;
    }

    .email-split {
      display: flex;
    }
  }
}