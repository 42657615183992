.PromoCardLongImgLeft {
  .promo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .promo-text {
    text-decoration: none;
  }

  .card-text {
    color: black;
    transition: all 400ms ease;
  }

  .card-text:hover {
    color: #3F612D;
  }

  .promo-row {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    width: 920px;
  }

  .promo-col {
    width: 100%;
  }

  .promo-image-long {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
    transition: all 800ms ease;
    filter: brightness(90%) grayscale(10%);
  }

  .promo-image-long:hover {
    filter: brightness(100%) grayscale(0%);
  }

  .promo-left {
    margin-right: 40px;
  }

  .promo-right {
    margin-left: 40px;
  }

  .promo-left-long {
    display: flex;
    justify-content: end;
  }

  .lg-view {
    display: block;
  }

  .sm-view {
    display: none;
  }

  .promo-text-left {
    text-align: right;
  }

  .promo-text-long-desc {
    white-space: pre-line;
  }

  @media screen and (max-width: 1200px) {
    .promo-left {
      margin-right: 20px;
    }
  
    .promo-right {
      margin-left: 20px;
    }
    
    .promo-row {
      padding: 0px 5%;
      width: 800px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .promo-row {
      display: block;
      padding: 0 10%;
    }

    .promo-left {
      margin-right: 0px;
      margin-bottom: 45px;
    }
  
    .promo-right {
      margin-left: 0px;
    }

    .lg-view {
      display: none;
    }

    .sm-view {
      display: block;
      margin-top: 45px;
      margin-bottom: 0px !important;
    }

    .promo-image-long {
      width: 100%;
      aspect-ratio: 3/2;
      height: auto;
      object-fit: cover;
      object-position: top center;
    }

    .promo-left-long {
      justify-content: center;
    }
  }
  
  @media screen and (max-width: 576px) {
    .promo-left {
      margin-bottom: 25px;
    }

    .sm-view {
      margin-top: 25px;
      margin-bottom: 0px !important;
    }
  }
}